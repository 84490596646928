@media screen and (max-width: 1200px) {
  /* .head_sticky li,
  .rowItem li {
    font-size: 0.675em;
  } */

  .head_sticky li:nth-child(3),
  .rowItem li:nth-child(3) {
    width: 7%;
  }

  .head_sticky li:nth-child(4),
  .rowItem li:nth-child(4) {
    width: 10%;
  }
  .head_sticky li:nth-child(5),
  .rowItem li:nth-child(5) {
    width: 5%;
  }

  .historyCell .dropdown-toggle.btn.btn-outline-secondary.btn-sm {
    --bs-btn-font-size: 0.675rem;
    padding: 5px;
  }
}
@media screen and (max-width: 769px) {
  .head_sticky li,
  .rowItem li {
    font-size: 0.675em;
  }

  .head_sticky li:nth-child(3),
  .rowItem li:nth-child(3) {
    width: 10%;
  }

  .head_sticky li:nth-child(4),
  .rowItem li:nth-child(4) {
    width: 15%;
  }
  .head_sticky li:nth-child(5),
  .rowItem li:nth-child(5) {
    width: 5%;
  }

  .historyCell .dropdown-toggle.btn.btn-outline-secondary.btn-sm {
    --bs-btn-font-size: 0.575rem;
    padding: 5px;
  }
}

@media screen and (max-width: 375px) {
  .head_sticky li,
  .rowItem li {
    font-size: 0.475em;
  }

  .head_sticky li:nth-child(1),
  .rowItem li:nth-child(1) {
    border-right: 1px solid #dee2e6;
    width: 20%;
  }
  .rowItem li:nth-child(1) {
    width: calc(20% - 1px);
  }

  .head_sticky li:nth-child(2),
  .rowItem li:nth-child(2) {
    width: 50%;
  }
  .rowItem li:nth-child(2) {
    width: calc(50% + 1px);
  }

  .head_sticky li:nth-child(3),
  .rowItem li:nth-child(3) {
    width: 7%;
  }
  .rowItem li:nth-child(3) {
    width: calc(10% - 1px);
  }

  .head_sticky li:nth-child(4),
  .rowItem li:nth-child(4) {
    width: 10%;
  }
  .rowItem li:nth-child(4) {
    width: calc(10% + 1px);
  }
  .head_sticky li:nth-child(5),
  .rowItem li:nth-child(5) {
    width: 10%;
  }

  .historyCell .dropdown-toggle.btn.btn-outline-secondary.btn-sm {
    --bs-btn-font-size: 0.475rem;
    padding: 5px;
  }
}

@media (min-width: 3800px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 3740px;
  }
}

@media (min-width: 1300px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: calc(100% - 50px);
  }
}
