@font-face {
  font-family: "Arvo-Regular";
  src: local("Arvo-Regular"), url("./assets/fonts/Arvo-Regular.ttf") format("ttf");
}

@font-face {
  font-family: "Bitstream Vera Serif Bold";
  src: url("https://mdn.github.io/css-examples/web-fonts/VeraSeBd.ttf");
}

:root {
  --column1_width: 20%;
  --column2_width: 25%;
  --column3_width: 5%;
  --column4_width: 10%;
  --column5_width: 5%;
  --column6_width: 5%;
  --column7_width: 10%;
  --column8_width: 20%;

  --borderMain: 1px solid #dee2e6;
  /*colors*/
  --button_tappable: #ef476f;
  --label: #fb8500;
  --tap_fill: #10696a;
  --label_data: #e87b00;
  --dinamic_label: #ee476f;
  --date_time_picker: #1189b1;
  --drop_down_list: #bab9a0;
  --calculation: #a3d4d6;
  --toggle_button: #e3bf86;
  --success_color: #55e145;

  --smokeGaude_color: #fe5d07;
  --blueCheese_color: #0486e5;
  --mauncheygo_color: #eb2c41;
  --asiago_color: #733c68;
  --billionare_bacon_color: #da453f;
  --dry_aged_color: #93692c;
  --spicy_calabrese_color: #e73500;
  --sel_gris_color: #767467;
  --hawaii_red_color: #e7740b;
  --black_lavor_color: #636557;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: rgb(217, 245, 249);
}

body::-webkit-scrollbar {
  display: none;
  border-collapse: collapse;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.header {
  position: relative;
}
.header button {
  position: fixed;
  top: 80px;
  right: 50px;
  z-index: 10;
}

.hideblock {
  display: none !important;
}

/* .container_list::-webkit-scrollbar {
  display: none;
} */
.arvo_font {
  font-family: "Arvo-Regular";
}

.success_color {
  color: var(--success_color);
}

.container_list {
  margin-bottom: 60px;
}

#import,
#export {
  display: none;
}

.cse.input-group {
  align-items: center;
}

label.form-label[for="export"] {
  background-color: #198544;
  padding: 5px 10px;
  border-radius: 10px;
  margin: 0;
  color: #fff;
  font-weight: 700;
  margin-right: 20px;
}

label.descr.form-label {
  margin: 0;
  padding: 0;
}

label.form-label[for="import"] {
  background-color: #b15e11;
  padding: 5px 10px;
  border-radius: 10px;
  margin: 0;
  color: #fff;
  font-weight: 700;
  margin-right: 20px;
}

label.form-label[for="export"]:hover,
label.form-label[for="import"]:hover {
  cursor: pointer;
}

.head_sticky,
.rowItem {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: var(--borderMain);
  border-inline: var(--borderMain);
}

.head_sticky {
  width: 100%;
  height: 60px;
}

.rowItem:hover {
  background: #ececec;
}

.head_sticky li,
.rowItem li {
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 0.875em;
  font-weight: 600;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rowItem li {
  font-weight: normal;
}

.rowItem:nth-child(2n) {
  background-color: #f2f2f2;
}

.head_sticky li p,
.rowItem li p {
  margin: 0;
  padding: 0;
}

.head_sticky li:nth-child(1),
.rowItem li:nth-child(1) {
  width: var(--column1_width);
}
.rowItem li:nth-child(1) {
  padding-left: 10px;
  justify-content: start;
  text-align: start;
}

.head_sticky li:nth-child(2),
.rowItem li:nth-child(2) {
  width: var(--column2_width);
}
.rowItem li:nth-child(2) {
  padding-left: 10px;
  justify-content: start;
  text-align: start;
}

.head_sticky li:nth-child(3),
.rowItem li:nth-child(3) {
  width: var(--column3_width);
}

.head_sticky li:nth-child(4),
.rowItem li:nth-child(4) {
  width: var(--column4_width);
}
.rowItem li:nth-child(4) {
  padding: 10px;
}
.head_sticky li:nth-child(5),
.rowItem li:nth-child(5) {
  width: var(--column5_width);
}
.head_sticky li:nth-child(6),
.rowItem li:nth-child(6) {
  width: var(--column6_width);
}

.head_sticky li:nth-child(7),
.rowItem li:nth-child(7) {
  width: var(--column7_width);
}

.head_sticky li:nth-child(8),
.rowItem li:nth-child(8) {
  width: var(--column8_width);
}

.tabList,
.productionList,
.employList {
  list-style: none;
  margin-bottom: 0px;
  padding: 0;
}

.tabList li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: var(--borderMain);
}

.tabList li p {
  font-size: 30px;
  font-weight: 700;
}

.dateAsHeader {
  text-align: center;
  font-size: 40px;
  font-style: italic;
  font-weight: 600;
}

.productionList li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.productionList li p {
  margin: 0;
  padding: 10px;
  /* border: 1px solid #dee2e6; */
  width: 50%;
  font-size: 30px;
  font-weight: 700;
}

.header_in_tabs {
  margin: 0;
  padding: 0;
  font-size: 35px;
  font-weight: 800;
  text-align: center;
  margin-bottom: 20px;
}

.tab_container.ss {
  margin-bottom: 60px;
  max-height: calc(100vh - 265px);
  overflow: auto;
}

.tab_container.daily {
  margin-bottom: 60px;
  max-height: calc(100vh - 255px);
  overflow: auto;
}

.tab_container::-webkit-scrollbar {
  display: none;
}

.mb30 {
  margin-bottom: 50px;
}

.employList li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: var(--borderMain);
}

.employList li p {
  margin: 0;
  padding: 10px;
  width: 50%;
  font-size: 40px;
  font-weight: 700;
}

.input-group .fs_c_30,
.form-control.fs_c_30,
.form-select.fs_c_30,
.fs_c_30,
.badge.fs_c_30 {
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
}

.input-group .fs_c_40,
.form-control.fs_c_40,
.fs_c_40 {
  font-size: 40px;
  font-weight: 700;
}
.fs_c_50,
.btn.fs_c_50,
.badge.fs_c_50 {
  font-size: 50px;
  font-weight: 700;
  line-height: 40px;
}

.btn.fs_c_50 {
  line-height: 40px;
  display: block;
  padding-bottom: 20px;
}

.badge.fs_c_100 {
  font-size: 60px;
}

.btn.fs_c_100 {
  font-size: 100px;
  line-height: 80px;
  display: block;
  padding-bottom: 30px;
}

.fs_c_20,
.btn.fs_c_20,
.form-select.fs_c_20,
.form-control.fs_c_20,
.input-group-text.fs_c_20 {
  font-size: 20px;
  font-weight: 500;
  line-height: 40px;
}

.btn.fs_c_20 {
  line-height: 40px;
  display: block;
  padding: 5px 20px;
  /* padding-bottom: 10px; */
}

.label_color {
  color: var(--label);
}
.dt_picker {
  color: var(--date_time_picker);
}

.badge.fs_c_50,
.badge.fs_c_100 {
  color: #fff;
  background-color: var(--dinamic_label);
  margin-left: 25px;
}

.badge.fs_c_30 {
  color: #fff;
  background-color: var(--dinamic_label);
  margin-left: 30px;
}

.partial_backet h2 {
  margin: 0;
  padding: 0;
}

.partial_backet p {
  background-color: var(--tap_fill);
  font-size: 40px;
  border-radius: 10px;
  padding: 10px 25px;
  margin: 0;
  color: #fff;
}

.backet_list {
}
.backet_list li {
  padding: 10px;
  border-bottom: 1px solid var(--label);
}

.form-select.fs_c_20 {
}

.toast.save_popup {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #bcdfeb;
  padding: 10px 30px;
}

.save_popup div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.save_popup strong {
  font-size: 30px;
}

.save_popup small {
  font-size: 20px;
  margin-left: 20px;
}
.loaderfull,
.manager_popup {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loaderfull .spinner-border {
  width: 100px;
  height: 100px;
  border: 10px solid #ffc107;
  border-right-color: transparent;
}
.mb-6 {
  margin-bottom: 4em;
}

.nav-link.custom:hover {
  background-color: #a3d4d6;
}

.manager_popup {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
}

.manager_popup.man_page_pop {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.manager_popup .block {
  background-color: #fff;
  text-align: center;
  padding: 30px;
  border-radius: 20px;
}

.sectionCustom {
  /* border: 1px solid #000; */
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 1);
  border-radius: 10px;
  padding: 15px;
}

.sectionTitle {
  text-align: center;
}

.dashboard_section {
  margin-bottom: 70px;
}
.dashboard_section .title {
  margin: 0 auto;
  width: 30%;
  text-align: center;
  /* text-shadow: 0px 5px 6px rgba(44,19,12,0.5); */
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 1);
  margin-bottom: 15px;
  border-radius: 10px;
  padding: 10px 0;
}

.seasoning_table tbody tr {
  text-align: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}

.seasoning_table tbody tr td:last-child {
  font-size: 25px;
  color: var(--label);
}

.dropdown.drop_down_popups_list {
  position: fixed;
  right: 10px;
  top: 60px;
  z-index: 100;
}
.dropdown.drop_down_popups_list .dropdown-toggle {
  font-size: 35px;
}

.dropdown.drop_down_popups_list .dropdown-menu {
  font-size: 35px;
  background-color: #bed4db;
}

.dropdown.drop_down_popups_list .dropdown-menu .dropdown-item {
  border-bottom: 1px solid #10696a;
}

.popup_container {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup_container.man_page_pop {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.popup_container .block {
  background-color: #fff;
  text-align: center;
  padding: 30px;
  border-radius: 20px;
  width: 95%;
  position: relative;
  max-height: 85%;
  overflow: auto;
}
.popup_container .block.sign {
  width: 400px;
  text-align: left;
}

.popup_container .block.select {
  height: 70%;
}

.popup_container .block.select .ss_select {
  font-size: 30px;
}

.block.daily {
  border: 1px solid #000;
}

.close_button {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10000;
  cursor: pointer;
}

.close_button svg {
  color: "#511f2e";
  padding: 10px;
}
.total_minutes {
  text-align: right;
  font-size: 20px;
  margin-bottom: 20px;
}
.total_minutes span {
  font-size: 30px;
  font-weight: 700;
}
.save_button {
  position: absolute;
  top: 0px;
  left: 30px;
}
.clean_ups_title {
  text-align: center;
  font-size: 25px;
  line-height: 50px;
}

.clean_ups_title::first-letter {
  text-transform: uppercase;
}

.daily_tabs_block {
  border: 1px solid #000;
}
.button_verify.btn {
  padding: 0 10px;
  font-size: 25px;
  font-weight: bolder;
}

.date_now_station {
  position: fixed;
  font-size: 20px;
  font-weight: 700;
  z-index: 999;
  background-color: #fff;
}
.date_now_station p {
  margin: 0;
  padding: 0;
}

.dashboard_monitor_container {
  font-size: 30px;
  font-family: "Avro-Regular";
}
.dashboard_monitor_container .row_cc {
  height: 40px;
}
.dashboard_monitor_container .row_cc.limited {
  height: 20px;
}

.dashboard_monitor_container .head_item,
.dashboard_monitor_container .orders_head_item,
.dashboard_monitor_container .head_item_manager {
  text-align: center;
  border: 2px solid rgb(222, 226, 230);
  font-size: 18px;
  vertical-align: middle;
  text-transform: uppercase;
}

.dashboard_monitor_container .head_item_manager {
  font-size: 13px;
}

.dashboard_monitor_container .orders_head_item {
  font-size: 13px;
}

.dashboard_monitor_container .flavor_item {
  text-align: center;
  color: #fff;
  border: 2px solid rgb(222, 226, 230);
  font-size: 25px;
  width: 25%;
  vertical-align: middle;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.dashboard_monitor_container .flavor_item.manager {
  width: 15%;
}

.dashboard_monitor_container .flavor_item.limited {
  font-size: 20px;
  padding: 0;
}

.dashboard_monitor_container .value_item,
.dashboard_monitor_container .order_item,
.dashboard_monitor_container .value_item.manager {
  position: relative;
  text-align: center;
  border: 2px solid rgb(222, 226, 230);
  font-size: 25px;
  vertical-align: middle;
}

.dashboard_monitor_container .value_item.manager {
  font-size: 13px;
  padding: 0;
  width: 10%;
}

.dashboard_monitor_container .order_item {
  padding: 0;
  font-size: 17px;
}

.dashboard_monitor_container .order_item.notes {
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

.dashboard_monitor_container .value_item.limited {
  font-size: 20px;
  padding: 0;
}

.value_item .status {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  animation: none;
}

.value_item .status.loading {
  animation: colorChange 0.1s infinite alternate;
}

@keyframes colorChange {
  /* 0% {
    background-color: attr(colattr);
  } */
  100% {
    background-color: white;
  }
}

.selectpopup_title {
  position: relative;
  margin-bottom: 20px;
}
.selectpopup_title button {
  position: absolute;
  left: 0;
}

.selectpopup_text {
  font-size: 30px;
  font-weight: bolder;
}

.react-tagsinput-tag.custom {
  font-size: 13px;
  padding: 1px;
}

.titles {
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 18pt;
  line-height: 1.5;
  letter-spacing: 0;
}
.titles span {
  color: rgb(217, 48, 37);
}
.form_at_check {
  position: absolute;
  bottom: 25px;
  left: 100px;
}
.customInputField {
  border: none;
  border-bottom: 1px solid #333333;
}

.radioForm {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 20px;
}
.radioForm label {
  padding-left: 20px;
}

.next_btn {
  padding: 5px 20px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.141), 0 1px 3px 0 rgba(0, 0, 0, 0.122);
  background-color: #ffffff;
  color: rgb(0, 144, 176);
}

.optionPlaceholder {
  font-size: 12px;
  color: #808080;
}
.dividerDate{
  width: 100%;
  height: 2px;
  background-color: #000;
  /* position: absolute; */
}